@import '~aos/src/sass/aos.scss';

*,
::after,
::before {
    background-repeat: no-repeat;
    box-sizing: inherit;
}
html,
body {
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;

    background-color: rgb(239, 240, 243);

    font-family: Calibre, 'San Francisco', 'SF Pro Text', -apple-system, system-ui,
        BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Segoe UI', Arial, sans-serif;
    font-size: 20px;

    font-size: 18px;
    line-height: 1.6;

    font-style: normal;
    padding: 0;
    margin: 0;
    color: rgb(46, 68, 78);
    -webkit-font-smoothing: subpixel-antialiased;
}

button {
    font-family: Calibre, 'San Francisco', 'SF Pro Text', -apple-system, system-ui,
        BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'Segoe UI', Arial, sans-serif;
    font-size: 14px;
    line-height: 1.6;

}
#root {
    min-height: 100vh;
    display: grid;
}

::selection {
    background-color: #1F689B;
    color: #ffffff;
}
img {
    width: 100%;
    max-width: 100%;
    vertical-align: middle;
}
svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
    vertical-align: middle;
}

a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    transition: all 1s cubic-bezier(.25, .8, .25, 1);
    cursor: pointer;

    &:hover,
    &:focus {
        color: #009966;
        outline: 0;
    }
}
p {
    margin: 0 0 15px 0;
  }

  ul, ol {
    padding: 0;
    margin: 0;
    list-style: none;
  }
